import { hostFEV2 } from "util/host"

export function onClickV2LMSButtonHandler() {
  const secret = localStorage.getItem("_auth")

  if (!secret) {
    window.notification("", "Token tidak tersedia!", "danger")
    return
  }

  const url = `${hostFEV2}/auth/login?isFromV1=true&token=${secret}`
  window.open(url, "_blank")
  window.localStorage.clear()
  window.close()
}
