import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import jwt_decode from "jwt-decode"
import LoadingSpinner from "component/loading"
import ErrorUI from "component/error-ui"

import { useBaseContext } from "context/base"
import { getToken, firebaseApp } from "util/firebase"
import { blockedNotifPopUp } from "util/global"

import Logo from "../auth/components/logo"

export default function Redirect() {
  /* ========================================== Helpers ========================================= */
  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const secretQuery = queryParams.get("secret")
  const roleIdQuery = queryParams.get("role_id")
  const urlQuery = queryParams.get("url")

  /* ====================================== Consume Context ===================================== */
  const {
    login,
    setValue,
    postRequest,
    setProfile: setProfileBaseContext,
  } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isLoading, setIsLoading] = useState(true)

  /* ========================================= Functions ======================================== */
  async function initToken() {
    const fbToken = await getToken()
    if (fbToken) {
      localStorage.setItem("firebase_token", fbToken)
      await postRequest("firebase-token", {
        fb_token_web: fbToken,
      })
    }
  }

  async function directLoginFromQuery({ secret, role_id, url }) {
    try {
      setIsLoading(true)
      var form = new FormData()
      form.append("secret", secret)
      form.append("role_id", role_id)

      let response = await postRequest("auth/v2/signin", form)
      if (response) {
        setIsLoading(false)

        if (response.accessToken !== null) {
          let tokenDecode = await jwt_decode(response.accessToken)
          localStorage.setItem(
            "all_profile",
            JSON.stringify(response.allProfile)
          )
          localStorage.setItem("_auth", response.secret)
          localStorage.setItem(
            "user",
            tokenDecode.user
              ? JSON.stringify(tokenDecode.user)
              : JSON.stringify(response?.akun?.user)
          )
          localStorage.setItem(
            "profile",
            tokenDecode.profile
              ? JSON.stringify(tokenDecode.profile)
              : JSON.stringify(response?.akun?.profile)
          )
          localStorage.setItem(
            "role_active",
            JSON.stringify(response.role_active)
          )
          localStorage.setItem(
            "menu_key",
            tokenDecode.menu_key
              ? JSON.stringify(tokenDecode.menu_key)
              : JSON.stringify(response?.menu_key)
          )
          localStorage.setItem(
            "login_id",
            tokenDecode.login_id
              ? JSON.stringify(tokenDecode.login_id)
              : JSON.stringify(response?.login_id)
          )
          localStorage.setItem("token", response.accessToken)
          localStorage.setItem("menus_allow", JSON.stringify(response.menus))
          localStorage.setItem("expired", "1")
          localStorage.setItem("semester", JSON.stringify(response.semester))
          setProfileBaseContext(
            tokenDecode.profile ? tokenDecode.profile : response?.akun?.profile
          )
          setValue("menus", response.menus)
          setValue("login", true)

          history.push(url || "dashboard")

          if (firebaseApp.messaging.isSupported()) {
            // CHECK NOTIFICATION
            if (!("Notification" in window)) {
              console.error(
                "This browser does not support desktop notification"
              )
            } else {
              const overlayElement = document.createElement("div")
              overlayElement.innerHTML = `
                <div class="notif-overlay">
                    <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
              `
              document.body.append(overlayElement)
              Notification.requestPermission().then(async (permit) => {
                if (permit === "granted") {
                  await initToken()
                  document.querySelector(".notif-overlay")?.remove()
                } else if (permit === "denied") {
                  document.querySelector(".notif-overlay")?.remove()
                  blockedNotifPopUp()
                } else {
                  document.querySelector(".notif-overlay")?.remove()
                }
              })

              setTimeout(
                () => document.querySelector(".notif-overlay")?.remove(),
                500
              )
            }
          } else {
            // Notify notification not supported
            window.$.notify(
              {
                icon: "feather icon-alert-circle",
                title: "Pemberitahuan",
                message:
                  "Browser yang anda gunakan tidak mendukung fitur notifikasi",
              },
              {
                element: "body",
                type: "success",
                allow_dismiss: true,
                placement: {
                  from: "top",
                  align: "center",
                },
                spacing: 10,
                z_index: 999999,
                delay: 0,
                url_target: "_blank",
                mouse_over: false,
                remove: true,
                template: `
                  <div data-notify="container" class="bg-light border col-xs-11 col-sm-3 alert alert-{0}" role="alert">
                    <button type="button" aria-hidden="true" class="close text-dark" data-notify="dismiss">×</button>
                    <span data-notify="icon" class="text-warning mr-1"></span> 
                    <span data-notify="title" class="text-dark f-14 f-w-700">{1}</span> 
                    <div data-notify="message" class="text-dark">{2}</div>
                  </div>
                `,
              }
            )
          }
        }
      }

      localStorage.setItem("msge", "0")
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheckDirectLogin = () => {
    const token = localStorage.getItem("token")

    if (token && login) {
      history.push(urlQuery || "/dashboard")
    } else {
      directLoginFromQuery({
        secret: secretQuery,
        role_id: roleIdQuery,
        url: urlQuery,
      })
    }
  }

  /* ========================================= Hooks  ============================================ */
  useEffect(() => {
    handleCheckDirectLogin()
  }, [location])

  return (
    <div className="auth-wrapper bg-primary">
      <Helmet>
        <title>LMS · Redirect</title>
      </Helmet>
      <div className="auth-content align-items-center text-center mb-5">
        <Logo />
        <div className="card mb-5">
          <div className="row align-items-center text-center">
            <div className="col-md-12">
              <div className="card-body">
                {isLoading ? (
                  <>
                    <LoadingSpinner />
                    <br />
                    <h5 class="mb-1">Sedang Memproses...</h5>
                  </>
                ) : (
                  <>
                    <ErrorUI />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        className="text-light"
        style={{
          position: "absolute",
          bottom: "7px",
          fontSize: "13px",
          fontWeight: "400",
        }}
      >
        <span>Copyright &copy; 2021</span>
        <a
          className="text-light ml-1"
          href="https://millennia-solusi.id"
          target="_blank"
          style={{
            fontWeight: "300",
          }}
          rel="noreferrer"
        >
          Millennia Solusi Informatika
        </a>
      </span>
    </div>
  )
}
