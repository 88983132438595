import React from 'react'
import useQuizEval from 'context/quiz-eval'
import QuizEvalColPrasyarat from './quiz-eval-col-prasyarat'
import BaseButton from 'component/button/BaseButton'
import useGetRoleActive from 'hooks/useGetRoleActive'
import { formatDate } from 'util/date'

export default function QuizEvalItem({ item, setIsShowModalReject }) {
  /* ====================================== Consume Context ===================================== */
  const { quiz } = useQuizEval()
  const { type } = useGetRoleActive()

  const isStatusRemedial = (item?.is_complete == null) && item?.history
  const isStatusCompleted = (item?.is_complete_nilai > 0) && (item?.is_checked > 0)
  /* ========================================== Output ========================================== */
  return (
    <tr>
      <td>{item.nim || '-'}</td>
      <td>{item.name || '-'}</td>
      <td>{item.email || '-'}</td>
      <td>{Number.isFinite(item.nilai) ? quiz.handle.nilai(item) : '-'}</td>
      <td>{quiz.handle.status(item)}</td>
      <td>{item?.start_date ? formatDate(item?.start_date) : '-'}</td>
      <td>
        <>
          {item.is_complete && !item.completed_by ? (
            <>
              <button
                type="button"
                className="btn btn-sm btn-info mr-2"
                onClick={() => quiz.handle.preview(item)}
              >
                <i className="feather icon-check-square mr-2" />
                Periksa
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger mr-2"
                onClick={() => {
                  quiz.detail.set(item)
                  setIsShowModalReject(true)
                }}
              >
                <i className="feather icon-x-circle mr-1" />
                Tolak Quiz
              </button>
            </>
          ) : '-'}

          {isStatusRemedial || isStatusCompleted ?
          <BaseButton isShow={type === 'administrator'} onClick={() => quiz.handle.reset(item)} variant='success' props={{className: 'btn-sm'}}>
            <i className='feather icon-refresh-cw'></i> Reset Quiz
          </BaseButton>
          : null}

          {(item.history || item.is_complete) && (
            <button
              className="btn btn-sm btn-warning mr-2"
              data-toggle="modal"
              data-target="#modalHistoryAssignment"
              onClick={() => quiz.history.set(item.history)}
            >
              <i className="fas fa-eye mr-1" />
              History
            </button>
          )}
        </>
      </td>
      <QuizEvalColPrasyarat item={item} />
    </tr>
  )
}
