import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import jwt_decode from 'jwt-decode'

import { useBaseContext } from 'context/base'
import Logo from './components/logo'
import { getToken, firebaseApp } from 'util/firebase'
import { blockedNotifPopUp } from 'util/global'
import { onClickV2LMSButtonHandler } from 'util/auth'

export default function Login() {
  /* ========================================== Helpers ========================================= */
  const history  = useHistory()
  const location = useLocation()

  /* ====================================== Consume Context ===================================== */
  const { setValue, localStorageClear, postRequest, setProfile: setProfileBaseContext } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [isLogin, setIsLogin]                 = useState(true)
  const [isProfile, setIsProfile]             = useState(false)
  const [isRoles, setIsRoles]                 = useState(false)
  const [username, setUsername]               = useState('')
  const [password, setPassword]               = useState('')
  const [isLoading, setIsLoading]             = useState(false)
  const [hidePassword, setHidePassword]       = useState(true)
  const [isUsernameEmpty, setIsUsernameEmpty] = useState(false)
  const [isPasswordEmpty, setIsPasswordEmpty] = useState(false)
  const [roles, setRoles]                     = useState([])
  const [profile, setProfile]                 = useState([])
  const [selectProfile, setSelectProfile]     = useState('')
  const [selectRole, setSelectRole]           = useState('')
  const [singleProfile, setSingleProfile]     = useState([])

  /* =========================================== Refs =========================================== */
  const refUsername = useRef()
  const refPass     = useRef()

  /* ========================================= Functions ======================================== */
  async function signinClick() {
    if ((username && password) || isRoles || isProfile) {
      setIsLoading(true)
      setIsUsernameEmpty(false)
      setIsPasswordEmpty(false)
      var form = new FormData()
      if (isRoles) {
        const secret = localStorage.getItem('secret')
        form.append('secret', secret)
        form.append(
          'role_id',
          selectRole === '' ? (roles[0] ? roles[0].id : null) : selectRole,
        )
      } else if (isProfile) {
        const secret = localStorage.getItem('secret')
        form.append('secret', secret)
        form.append(
          'profile_id',
          selectProfile === ''
            ? profile[0]
              ? profile[0].id
              : null
            : selectProfile,
        )
      } else if (username && password) {
        form.append('username', username)
        form.append('password', password)
      }
      let response = await postRequest('auth/v2/signin', form)
      setIsLoading(false)
      if (response) {
        if (response.accessToken !== null) {
          let tokenDecode = await jwt_decode(response.accessToken)
          localStorage.setItem('all_profile', JSON.stringify(response.allProfile))
          localStorage.setItem('_auth', response.secret)
          localStorage.setItem('user', tokenDecode.user ? JSON.stringify(tokenDecode.user) : JSON.stringify(response?.akun?.user))
          localStorage.setItem('profile', tokenDecode.profile ? JSON.stringify(tokenDecode.profile) : JSON.stringify(response?.akun?.profile))
          localStorage.setItem('role_active', JSON.stringify(response.role_active))
          localStorage.setItem('menu_key', tokenDecode.menu_key ? JSON.stringify(tokenDecode.menu_key) : JSON.stringify(response?.menu_key))
          localStorage.setItem('login_id', tokenDecode.login_id ? JSON.stringify(tokenDecode.login_id) : JSON.stringify(response?.login_id))
          localStorage.setItem('token', response.accessToken)
          localStorage.setItem('menus_allow', JSON.stringify(response.menus))
          localStorage.setItem('expired', '1')
          localStorage.setItem('semester', JSON.stringify(response.semester))
          setProfileBaseContext(tokenDecode.profile ? tokenDecode.profile : response?.akun?.profile)
          setValue('menus', response.menus)
          setValue('login', true)
          
          const roleSiswa = response.role_active?.role_type === 'siswa';
          if (roleSiswa) {
            // history.push('siswa-group')
            onClickV2LMSButtonHandler()
          } else {
            history.push('dashboard')
          }

          if (firebaseApp.messaging.isSupported()) {
            // CHECK NOTIFICATION
            if (!('Notification' in window)) {
              console.error('This browser does not support desktop notification')
            } else {
              const overlayElement = document.createElement('div')
              overlayElement.innerHTML = `
                <div class="notif-overlay">
                    <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                </div>
              `
              document.body.append(overlayElement)
              Notification.requestPermission().then(async (permit) => {
                if (permit === 'granted') {
                  await initToken()
                  document.querySelector('.notif-overlay')?.remove()
                } else if (permit === 'denied') {
                  document.querySelector('.notif-overlay')?.remove()
                  blockedNotifPopUp()
                } else {
                  document.querySelector('.notif-overlay')?.remove()
                }
              })

              setTimeout(
                () => document.querySelector('.notif-overlay')?.remove(),
                500,
              )
            }
          } else {
            // Notify notification not supported
            window.$.notify(
              {
                icon: 'feather icon-alert-circle',
                title: 'Pemberitahuan',
                message:
                  'Browser yang anda gunakan tidak mendukung fitur notifikasi',
              },
              {
                element: 'body',
                type: 'success',
                allow_dismiss: true,
                placement: {
                  from: 'top',
                  align: 'center',
                },
                spacing: 10,
                z_index: 999999,
                delay: 0,
                url_target: '_blank',
                mouse_over: false,
                remove: true,
                template: `
                  <div data-notify="container" class="bg-light border col-xs-11 col-sm-3 alert alert-{0}" role="alert">
                    <button type="button" aria-hidden="true" class="close text-dark" data-notify="dismiss">×</button>
                    <span data-notify="icon" class="text-warning mr-1"></span> 
                    <span data-notify="title" class="text-dark f-14 f-w-700">{1}</span> 
                    <div data-notify="message" class="text-dark">{2}</div>
                  </div>
                `,
              },
            )
          }
        } else if (response.message === 'Harap pilih role') {
          localStorage.removeItem('profiles')
          localStorage.setItem('profile', JSON.stringify(response.profile))
          localStorage.setItem('roles', JSON.stringify(response.data))
          localStorage.setItem('secret', response.secret)
          window.notification('Berhasil', `${response.message}`, 'success')
          setIsRoles(true)
          setIsProfile(false)
          setIsLogin(false)
        } else if (response.message === 'Harap pilih profile') {
          localStorage.setItem('profiles', JSON.stringify(response.data))
          localStorage.setItem('secret', response.secret)
          window.notification('Berhasil', `${response.message}`, 'success')
          setIsProfile(true)
          setIsRoles(false)
          setIsLogin(false)
        }
      }
      localStorage.setItem('msge', '0')
    } else {
      if (!username) {
        setIsUsernameEmpty(true)
      }
      if (!password) {
        setIsPasswordEmpty(true)
      }
    }
  }

  async function initToken() {
    const fbToken = await getToken()
    if (fbToken) {
      localStorage.setItem('firebase_token', fbToken)
      await postRequest('firebase-token', {
        fb_token_web: fbToken,
      })
    }
  }

  function checkParam() {
    if (location.search.includes('logout')) {
      localStorage.removeItem('user')
      localStorage.removeItem('_auth')
      localStorage.removeItem('profile')
      localStorage.removeItem('profiles')
      localStorage.removeItem('roles')
      localStorage.removeItem('secret')
      localStorage.removeItem('role_active')
      localStorage.removeItem('menu_key')
      localStorage.removeItem('login_id')
      localStorage.removeItem('menus_allow')
    } else if (localStorage.getItem('user') !== null) {
      window.location.replace('/dashboard')
    } else if (localStorage.getItem('user') === null) {
      localStorage.removeItem('token')
      localStorageClear()
    }
  }

  function getProfile() {
    if (localStorage.getItem('profiles')) {
      const profile = JSON.parse(localStorage.getItem('profiles'))
      const arr = []
      profile.map((i) => {
        arr.push({ id: i.id, name: i.name, desc: i.desc })
      })
      setProfile(arr)
      setIsProfile(true)
    } else {
      setIsProfile(false)
    }
    if (localStorage.getItem('profile')) {
      const prof = JSON.parse(localStorage.getItem('profile'))
      setSingleProfile(prof)
    }
  }

  function getRoles() {
    if (localStorage.getItem('roles')) {
      const roles = JSON.parse(localStorage.getItem('roles'))
      const arr = []
      roles.map((i) => {
        arr.push({ id: i.id, role: i.role, role_type: i.role_type })
      })
      setRoles(arr)
      setIsRoles(true)
    } else {
      setIsRoles(false)
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      checkParam()
    }

    return () => {
      isSubscribed = false
    }
  }, [])

  useEffect(() => {
    getProfile()
    getRoles()
  }, [isRoles, isProfile, isLogin])

  /* ======================================== Components ======================================== */
  function MessageExpired() {
    var msge = localStorage.getItem('expired')
    var token = localStorage.getItem('token')
    if (!msge && token) {
      return (
        <div
          className="alert alert-danger"
          role="alert"
        >
          Sesi telah berakhir
        </div>
      )
    } else {
      return null
    }
  }

  /* ========================================== Output ========================================== */
  return (
    <div className="auth-wrapper bg-primary">
      <Helmet>
        <title>LMS · Login</title>
      </Helmet>
      <div className="auth-content align-items-center text-center">
        <Logo />
        {isLogin && (
          <div className="card mb-5">
            <div className="row align-items-center text-center">
              <div className="col-md-12">
                <div className="card-body">
                  <MessageExpired />
                  <div className="form-group mb-3">
                    <label
                      className="floating-label"
                      htmlFor="Email"
                    >
                      Username
                    </label>
                    <input
                      ref={refUsername}
                      type="text"
                      value={username}
                      onChange={(e) => {
                        if (e.target.value) {
                          setIsUsernameEmpty(false)
                        }
                        setUsername(e.target.value)
                      }}
                      className="form-control"
                      id="Email"
                      onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                          signinClick()
                        }
                      }}
                    />
                    {isUsernameEmpty ? (
                      <span className="col small form-text text-danger text-left">
                        Username tidak boleh kosong.
                      </span>
                    ) : null}
                  </div>
                  <div className="row">
                    <div className="col-10 pr-0">
                      <div className="form-group mb-3">
                        <label
                          className="floating-label"
                          htmlFor="Password"
                        >
                          Password
                        </label>
                        <input
                          ref={refPass}
                          type={hidePassword ? 'password' : 'text'}
                          value={password}
                          onChange={(e) => {
                            if (e.target.value) {
                              setIsPasswordEmpty(false)
                            }
                            setPassword(e.target.value)
                          }}
                          className="form-control"
                          id="Password"
                          onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              signinClick()
                            }
                          }}
                        />
                        {isPasswordEmpty ? (
                          <span className="col small form-text text-danger text-left">
                            Password tidak boleh kosong.
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-2 pl-0">
                      <div className="form-group mt-2">
                        <button
                          onClick={() =>
                            setHidePassword(!hidePassword)
                          }
                          className="btn btn-light btn-sm rounded-circle"
                          type="button"
                        >
                          <i className="fa fa-eye pt-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <button
                      disabled={true}
                      type="button"
                      className="btn btn-block btn-primary mb-4"
                    >
                      <span
                        className="spinner-border spinner-border-sm mr-1"
                        role="status"
                      />
                      <span>Masuk</span>
                    </button>
                  ) : (
                    <button
                      className="btn btn-block btn-primary mb-4"
                      onClick={() => signinClick()}
                    >
                      Masuk
                    </button>
                  )}
                  <p className="mb-2 text-muted">
                    Lupa password?{' '}
                    <a
                      href="/reset"
                      className="f-w-400"
                    >
                      Reset
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRoles && <FormRole />}
        {isProfile && <FormProfile />}
      </div>
      <span
        className="text-light"
        style={{
          position: 'absolute',
          bottom: '7px',
          fontSize: '13px',
          fontWeight: '400',
        }}
      >
        <span>Copyright &copy; 2021</span>
        <a
          className="text-light ml-1"
          href="https://millennia-solusi.id"
          target="_blank"
          style={{
            fontWeight: '300',
          }}
          rel="noreferrer"
        >
          Millennia Solusi Informatika
        </a>
      </span>
    </div>
  )
  function FormRole() {
    return (
      <div className="card mb-5">
        <div className="row align-items-center text-center">
          <div className="col-md-12">
            <div className="card-body">
              <MessageExpired />
              <div className="form-group mb-3">
                <label
                  className="floating-label  text-primary"
                  htmlFor="profile"
                >
                  Profile
                </label>
                <br></br>
                <p className="text-left mt-2">
                  {singleProfile ? singleProfile.name : null}
                </p>
              </div>
              <div className="form-group mb-3">
                <label
                  className="floating-label  text-primary"
                  htmlFor="role"
                >
                  Role
                </label>
                <br></br>
                <select
                  defaultValue={selectRole}
                  className="form-control"
                  onChange={(event) => {
                    setSelectRole(event.target.value)
                  }}
                >
                  {roles.map((i, index) => (
                    <option
                      value={i.id}
                      key={index}
                    >
                      {i.role}
                    </option>
                  ))}
                </select>
              </div>
              {isLoading ? (
                <button
                  disabled={true}
                  type="button"
                  className="btn btn-block btn-primary mb-4"
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                  <span>Masuk</span>
                </button>
              ) : (
                <button
                  className="btn btn-block btn-primary mb-4"
                  onClick={() => signinClick()}
                >
                  Masuk
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function FormProfile() {
    return (
      <div className="card mb-5">
        <div className="row align-items-center text-center">
          <div className="col-md-12">
            <div className="card-body">
              <MessageExpired />
              <div className="form-group mb-3">
                <label
                  className="floating-label  text-primary"
                  htmlFor="profile"
                >
                  Profile
                </label>
                <br></br>
                <select
                  value={selectProfile}
                  //   defaultValue={profile[0].id}
                  className="form-control"
                  onChange={(event) => {
                    setSelectProfile(event.target.value)
                  }}
                >
                  {profile.map((i, index) => (
                    <option
                      value={i.id}
                      key={index}
                    >
                      {i.name}
                    </option>
                  ))}
                </select>
              </div>

              {isLoading ? (
                <button
                  disabled={true}
                  type="button"
                  className="btn btn-block btn-primary mb-4"
                >
                  <span
                    className="spinner-border spinner-border-sm mr-1"
                    role="status"
                  />
                  <span>Masuk</span>
                </button>
              ) : (
                <button
                  className="btn btn-block btn-primary mb-4"
                  onClick={signinClick}
                >
                  Masuk
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
