import React, { useState, useEffect, Fragment } from 'react'
import { useHistory } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import { avatarDefaultUser } from 'util/constant'
import { appendScriptReturnDoc, removeScript } from 'util/global'
import { hostAdminFEV2 } from 'util/host'

export default function Navbar() {
  /* ========================================== Helpers ========================================= */
  const history   = useHistory()
  const token       = localStorage.getItem('token')
  const secret      = localStorage.getItem("_auth")

  /* ====================================== Consume Context ===================================== */
  const { menus, refreshToken, setProfile } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [roleActive, setRoleActive]     = useState()
  const [menu, setMenu]                 = useState([])
  const [profiles, setProfiles]         = useState([])
  const [roleIdActive, setRoleIdActive] = useState(null)

  /* =================================== Consume Local Storage ================================== */
  const { linkAvatar, name } = JSON.parse(localStorage.getItem('user'))
  const { id: roleId, role_type } = JSON.parse(localStorage.getItem('role_active'))
  
  /* ========================================= Functions ======================================== */
  function getProfile() {
    let profiles = JSON.parse(localStorage.getItem('all_profile'))
    if (profiles) {
      setProfiles(profiles)
    }
  }

  async function getMenus() {
    let menus = localStorage.getItem('menus_allow')
    if (menus) {
      let json = JSON.parse(menus)
      setMenu(json)
    }
    let ra = localStorage.getItem('role_active')
    if (ra) {
      let j = JSON.parse(ra)
      setRoleIdActive(j.id)
      if (j.hasOwnProperty('role')) {
        if (j.role == 'Instruktur') {
          setRoleActive('Guru')
        } else {
          setRoleActive(j.role)    
        }
      } else {
        setRoleActive('')
      }
    }
  }
  
  /* ========================================== Effects ========================================= */
  // useEffect(() => {
  //   setTimeout(() => {
  //     appendScript('/admin/assets/js/pcoded.min.js')
  //   }, 10)
  // }, [])

  useEffect(() => {
    setTimeout(() => {
      appendScriptReturnDoc('/admin/assets/js/pcoded.min.js', 'append-script-pcoded')
    }, 10)

    return () => {
      removeScript('append-script-pcoded')
    }
  }, [])

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      getMenus()
      getProfile()
    }
    return () => (isSubscribed = false)
  }, [menus])

  /* ========================================== Output ========================================== */
  return (
    <nav className="pcoded-navbar menu-light menupos-fixed">
      <div className="navbar-wrapper  ">
        <div className="navbar-content scroll-div ">
          <div>
            <div className="main-menu-header">
              <img
                className="img-radius"
                style={{ height: '60px', width: '60px' }}
                src={`${linkAvatar}`}
                alt={name ? name : 'Avatar'}
                onError={(event) =>
                  (event.currentTarget.src = avatarDefaultUser)
                }
              />
              <div className="user-details">
                <div id="more-details">
                  {roleActive} <i className="fa fa-caret-down" />
                </div>
              </div>
            </div>
            <div
              className="collapse"
              id="nav-user-link"
            >
              <ul className="list-inline">
                <li className="list-inline-item">
                  <a
                    href="/profile"
                    data-toggle="tooltip"
                    title="Profile"
                  >
                    <i className="feather icon-user" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="/login?logout"
                    data-toggle="tooltip"
                    title="Logout"
                    className="text-danger"
                    onClick={() => setProfile(null)}
                  >
                    <i className="feather icon-power" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ul className="nav pcoded-inner-navbar ">
            <li className="nav-item pcoded-menu-caption">
              <label>Navigation</label>
            </li>
            <li
              onMouseDown={() => history.push('/dashboard')}
              className="nav-item"
              style={{ cursor: 'pointer' }}
            >
              {role_type === 'orangtua' ? null : (
                <a
                  href="#"
                  className="nav-link "
                >
                  <span className="pcoded-micon">
                    <i className="feather icon-home" />
                  </span>
                  <span className="pcoded-mtext">Dashboard</span>
                </a>
              )}
            </li>
            <li className="nav-item pcoded-hasmenu">
              <a className="nav-link ">
                <span className="pcoded-micon">
                  <i className="feather icon-user"></i>
                </span>
                <span className="pcoded-mtext">Change Profile & Role</span>
              </a>
              <ul className="pcoded-submenu">
                {profiles.map((i, idx) => {
                  return (
                    <li
                      className="pcoded-hasmenu"
                      key={idx}
                      id={i.id}
                    >
                      <a id={i.id}>{i.name}</a>
                      <ul
                        className="pcoded-submenu"
                        id={i.id}
                      >
                        {i.roles.map((i, index) => {
                          return (
                            <RenderProfile
                              i={i}
                              key={index}
                            />
                          )
                        })}
                      </ul>
                    </li>
                  )
                })}
              </ul>
            </li>
            <Fragment>
              {menu.map((val, i) => {
                return (
                  <li
                    key={val.menu}
                    className={`${
                      val.parent.length > 0
                        ? 'nav-item pcoded-hasmenu'
                        : 'nav-item'
                    }`}
                  >
                    {val.parent.length > 0 ? (
                      <a
                        type="button"
                        className="nav-link"
                      >
                        <span className="pcoded-micon">
                          <i
                            className={`${val.icon ? val.icon.toString() : ''}`}
                          />
                        </span>
                        <span className="pcoded-mtext">{val.menu}</span>
                      </a>
                    ) : (
                      <a
                        onMouseDown={() => {
                          if(!!val?.is_new){
                            window.open(`${hostAdminFEV2}/redirect?token=${token}&secret=${secret}&role_id=${roleId}&url=${val?.url}`, "_self")
                            window.localStorage.clear()
                            window.close()
                          } else {
                            history.push(val.url)
                          }
                        }}
                        className="nav-link"
                        style={{ cursor: 'pointer' }}
                      >
                        <span className="pcoded-micon">
                          <i
                            className={`${val?.icon ? val.icon.toString() : ''}`}
                          />
                        </span>
                        <span className="pcoded-mtext">{val?.menu}</span>
                      </a>
                    )}
                    {val?.parent?.length > 0 ? (
                      <ul className="pcoded-submenu">
                        {val?.parent?.map((v, i) => {
                          return (
                            <li key={v.menu}>
                              <a
                                style={{ cursor: 'pointer' }}
                                onMouseDown={() => {
                                  if(!!v?.is_new){
                                    window.open(`${hostAdminFEV2}/redirect?token=${token}&secret=${secret}&role_id=${roleId}&url=${v?.url}`, "_blank")
                                    window.localStorage.clear()
                                    window.close()
                                  } else {
                                    history.push(v.url)
                                  }
                                }}
                              >
                                {v.menu}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    ) : null}
                  </li>
                )
              })}
            </Fragment>
          </ul>
        </div>
      </div>
    </nav>
  )
  
  /* ======================================== Components ======================================== */
  function RenderProfile({ i }) {
    useEffect(() => {
      window.$('#demoUp' + i.id).on('click', async function demo() {
        const roleIdActive = JSON.parse(localStorage.getItem('role_active'))?.id
        if (roleIdActive !== i.id) {
          const overlayElement = document.createElement('div')
          overlayElement.innerHTML = `
            <div class="notif-overlay">
                <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
          `
          document.body.append(overlayElement)

          setTimeout(
            () => document.querySelector('.notif-overlay')?.remove(),
            500,
          )

          const role = i.id
          const profile = i.profile_id
          await refreshToken(role, profile, true)
            .then((response) => {
              if (response) {
                document.querySelector('.notif-overlay')?.remove()
                history.replace('/')
                // location.reload()
              } else {
                document.querySelector('.notif-overlay')?.remove()
              }
            })
            .catch((err) => {
              document.querySelector('.notif-overlay')?.remove()
            })
        }
      })
    }, [])

    return (
      <li key={i?.id}>
        <a
          type="button"
          id={'demoUp' + i?.id}
        >
          {i?.role === 'Instruktur' ? 'Guru' : i?.role}{' '}
          {roleIdActive == i?.id ? (
            <span className="badge badge-success">Aktif</span>
          ) : null}
        </a>
      </li>
    )
  }
}
