import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useBaseContext } from 'context/base'
import TemplateAdmin from 'component/admin/TemplateAdmin'
import { Container, Header } from 'component/template'

export default function MenusAdd() {
  /* ========================================== Helpers ========================================= */
  const { id }  = useParams()
  const history = useHistory()

  /* ====================================== Consume Context ===================================== */
  const { getRequest, postRequest, putRequest } = useBaseContext()

  /* ======================================= Local States ======================================= */
  const [title, setTitle]                   = useState('Tambah')
  const [menu, setMenu]                     = useState()
  const [menuKey, setMenuKey]               = useState()
  const [menuUrl, setMenuUrl]               = useState()
  const [menuIcon, setMenuIcon]             = useState()
  const [menuTablerIcon, setMenuTablerIcon] = useState()
  const [roleType, setRoleType]             = useState([])
  const [type, setType]                     = useState('menu')
  const [defa, setDefa]                     = useState([])
  const [orders, setOrders]                 = useState(0)
  const [parentId, setParentId]             = useState(0)
  const [isNewMenu, setIsNewMenu]           = useState(false)
  const [administrator, setAdministrator]   = useState(false)
  const [instruktur, setInstruktur]         = useState(false)
  const [siswa, setSiswa]                   = useState(false)

  /* ========================================= Functions ======================================== */
  async function getName() {
    if (id) {
      let response = await getRequest('menus-id?id=' + id)
      if (response) {
        const { data } = response
        var rt = JSON.parse(data.role_type)
        setMenu(data.menu)
        setMenuKey(data.menu_key)
        setMenuUrl(data.url)
        setMenuIcon(data.icon)
        if(data?.tabler_icon){
          setMenuTablerIcon(data?.tabler_icon)
        }
        setType(data.type)
        setOrders(data.orders)
        setParentId(data.parent_id)
        setRoleType(rt)
        if(data?.is_new){
          setIsNewMenu(true)
        }
        setTitle('Edit')
        var k =
          rt.filter((val) => val == 'kepalasekolah').length == 0 ? false : true
        var a =
          rt.filter((val) => val == 'administrator').length == 0 ? false : true
        var i =
          rt.filter((val) => val == 'instruktur').length == 0 ? false : true
        var s = rt.filter((val) => val == 'siswa').length == 0 ? false : true
        var o = rt.filter((val) => val == 'orangtua').length == 0 ? false : true
        var ap =
          rt.filter((val) => val == 'adminprofile').length == 0 ? false : true
        var dd = [
          { value: 'kepalasekolah', status: k },
          { value: 'administrator', status: a },
          { value: 'instruktur', status: i },
          { value: 'siswa', status: s },
          { value: 'orangtua', status: o },
          { value: 'adminprofile', status: ap },
        ]
        setDefa(() => dd)
        setAdministrator(a)
      }
    } else {
      var dd = [
        { value: 'kepalasekolah', status: false },
        { value: 'administrator', status: false },
        { value: 'instruktur', status: false },
        { value: 'siswa', status: false },
        { value: 'orangtua', status: false },
        { value: 'adminprofile', status: false },
      ]
      setDefa(() => dd)
    }
  }

  function setTipeRole(nama_role, status) {
    if (status) {
      var d = roleType
      d.push(nama_role)
      setRoleType(d)
    } else {
      var d = roleType
      var fil = d.filter((val) => val != nama_role)
      setRoleType(fil)
    }
  }
  
  async function saveMenu() {
    let profile = JSON.parse(localStorage.getItem('profile'))
    if (id) {
      var form = new FormData()
      form.append('menu', menu)
      form.append('menu_key', menuKey)
      form.append('url', menuUrl)
      form.append('icon', menuIcon)
      if(menuTablerIcon){
        form.append('tabler_icon', menuTablerIcon)
      }
      form.append('is_new', isNewMenu)
      form.append('type', type)
      form.append('orders', orders)
      form.append('parent_id', parentId)
      form.append('profile_id', profile.id)
      form.append('role_type', JSON.stringify(roleType))
      form.append('id', id)
      let response = await putRequest('save-menus', form)
      if (response) {
        window.notification(
          'Success !',
          'Data menu berhasil diperbarui',
          'success',
        )
      }
    } else {
      var form = new FormData()
      form.append('menu', menu)
      form.append('menu_key', menuKey)
      form.append('url', menuUrl)
      form.append('icon', menuIcon)
      if(menuTablerIcon){
        form.append('tabler_icon', menuTablerIcon)
      }
      form.append('is_new', isNewMenu)
      form.append('type', type)
      form.append('profile_id', profile.id)
      form.append('role_type', JSON.stringify(roleType))
      for(const v of form){
        console.log('v', v)
      }
      let response = await postRequest('save-menus', form)
      if (response) {
        window.notification(
          'Success !',
          'Data menu berhasil ditambahkan',
          'success',
        )
      }
    }
  }

  /* ========================================== Effects ========================================= */
  useEffect(() => {
    getName()
  }, [])
  
  /* ========================================== Output ========================================== */
  return (
    <TemplateAdmin>
      <Container>
        <Header pageName="Menu" />

        <div className="row">
          {/* [ Form Validation ] start */}
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h5>{title} Menu</h5>
              </div>
              <div className="card-body">
                <form
                  id="validation-form123"
                  action="#!"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <div className="form-group">
                            <label className="form-label">Nama Menu</label>
                            <input
                              value={menu}
                              onChange={(val) => setMenu(val.target.value)}
                              type="text"
                              className="form-control"
                              name="role"
                              placeholder="Nama Menu"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Menu Key</label>
                            <input
                              value={menuKey}
                              onChange={(val) => setMenuKey(val.target.value)}
                              type="text"
                              className="form-control"
                              name="role"
                              placeholder="Menu key"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Url</label>
                            <input
                              value={menuUrl}
                              onChange={(val) => setMenuUrl(val.target.value)}
                              type="text"
                              className="form-control"
                              name="role"
                              placeholder="Menu URL"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Icon</label>
                            <input
                              value={menuIcon}
                              onChange={(val) => setMenuIcon(val.target.value)}
                              type="text"
                              className="form-control"
                              name="role"
                              placeholder="Menu Icon"
                              required={true}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Tabler Icon</label>
                            <input
                              value={menuTablerIcon}
                              onChange={(val) => setMenuTablerIcon(val.target.value)}
                              type="text"
                              className="form-control"
                              name="role"
                              placeholder="Menu Tabler Icon"
                              required={false}
                            />
                          </div>
                          <div className="form-group">
                            <label className="form-label">Tipe</label>
                            <select
                              value={type}
                              onChange={(val) => setType(val.target.value)}
                              className="form-control"
                            >
                              <option value="menu">Menu</option>
                              <option value="function">Fungsi</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label className="form-label">Role tipe</label>
                            <div classname="form-check">
                              {defa.map((val, i) => {
                                return (
                                  <>
                                    <input
                                      value={val.value}
                                      defaultChecked={val.status}
                                      classname="form-check-input"
                                      onChange={(v) =>
                                        setTipeRole(val.value, v.target.checked)
                                      }
                                      type="checkbox"
                                      id="gridCheck"
                                    />
                                    &nbsp;
                                    <label
                                      classname="form-check-label"
                                      htmlFor="gridCheck"
                                    >
                                      {val.value}
                                    </label>
                                    &nbsp;&nbsp;
                                  </>
                                )
                              })}
                            </div>
                          </div>
                          <div className='form-group'>
                            <label className="form-label">Apakah Menu Baru?</label>
                            <div classname="form-check">
                              <input
                                value={true}
                                defaultChecked={isNewMenu}
                                classname="form-check-input"
                                onChange={(value) =>
                                  setIsNewMenu(!!value?.target?.checked)
                                }
                                type="checkbox"
                                id="formCheckIsNewMenu"
                              />
                              &nbsp;
                              <label
                                classname="form-check-label"
                                htmlFor="formCheckIsNewMenu"
                              >
                                Ya
                              </label>
                            </div>
                          </div>
                          <button
                            type="button"
                            onClick={() => history.goBack()}
                            className="btn btn-outline-danger has-ripple"
                          >
                            Kembali
                            <span
                              className="ripple ripple-animate"
                              style={{
                                height: '87.8281px',
                                width: '87.8281px',
                                animationDuration: '0.7s',
                                animationTimingFunction: 'linear',
                                background: 'rgb(255, 255, 255)',
                                opacity: '0.4',
                                top: '-26.9062px',
                                left: '-13.3125px',
                              }}
                            />
                          </button>
                          &nbsp;&nbsp;&nbsp;
                          <button
                            type="button"
                            onClick={() => saveMenu()}
                            className="btn btn-success has-ripple"
                          >
                            Simpan
                            <span
                              className="ripple ripple-animate"
                              style={{
                                height: '90.8281px',
                                width: '90.8281px',
                                animationDuration: '0.7s',
                                animationTimingFunction: 'linear',
                                background: 'rgb(255, 255, 255)',
                                opacity: '0.4',
                                top: '-26.4062px',
                                left: '0.65625px',
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </TemplateAdmin>
  )
}
